import CryptoJS from 'crypto-js';

const key = 'password';

function encryptByAES(data) {
  return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}
function decrytpFromAES(data) {
  let result = null;
  try{
    let bytes = CryptoJS.AES.decrypt(data, key);
    result = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
  } catch(error) {
    result = null;
  }
  return result;
}
export const common = {
  formatDateTime() {
    let dateValue = new Date();
    let year = dateValue.getFullYear();
    let month = (dateValue.getMonth() + 1).toString().padStart(2, '0');
    let day = dateValue.getDate().toString().padStart(2, '0');
    let hour = dateValue.getHours().toString().padStart(2, '0');
    let minute = dateValue.getMinutes().toString().padStart(2, '0');
    let second = dateValue.getSeconds().toString().padStart(2, '0');
    return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
  },
  /**
   * @desc 设置localStorage
   */
  setLocal(options) {
    let { key, value } = options;
    localStorage.setItem(key, encryptByAES(value));
  },
  /**
   * @desc 读取localStorage
   */
  getLocal(key) {
    let localString = localStorage.getItem(key);
    return localString ? decrytpFromAES(localString) : '';
  },
  /**
   * @desc 设置sessionStorage
   */
  setSession(options) {
    let {key, value} = options;
    sessionStorage.setItem(key, encryptByAES(value));
  },
  /**
   * @desc 读取sessionStorage
   */
  getSession(key) {
    let sessionString = sessionStorage.getItem(key);
    return sessionString ? decrytpFromAES(sessionString) : '';
  },
  /**
   * @desc 可关闭message方法 type类型（success,error,warning,info）
   */
  message(vue, type, msg) {
    switch (type) {
      case 'success':
        vue.$message({
          showClose: true,
          message: msg,
          type: 'success'
        })
        break;
      case 'error':
        vue.$message({
          showClose: true,
          message: msg,
          type: 'error'
        })
        break;
      case 'warning':
        vue.$message({
          showClose: true,
          message: msg,
          type: 'warning'
        })
        break;
      case 'info':
        vue.$message({
          showClose: true,
          message: msg,
          type: 'info'
        })
        break;
    }
  },
  /**
   * @desc 重新双向数据绑定方法
   */
  bindForm(vue,val,form,key){
    vue.$set(vue[form],key,val)
  },
  /**
   * @desc 数字过滤器
   */
  numFilter(value,digits) {
    value = parseFloat(value)
    return  value.toFixed(digits)
  },
  /**
   * @desc 过滤多余字符
   */
  pass2LongStr(value,digits){
    if (typeof value==='string') {
      let arr = value.split('',digits)
      if (arr.length===digits) {
        return arr.join('') + '...'
      }
      return arr.join('')
    }
  },
  /**
   * @desc 返回字符串长度是否符合
   */
  testStrLength(str, length) {
    let strLength = str.split('').length
    return strLength>length
  },
  /**
   * @desc 根据元素id算出高度
   */
  getHeight(id,height) {
    if (id!=='') {
      let element = '#'+id
      let a = window.innerHeight
      let b = document.querySelector(element)?.clientHeight
      if (height!==undefined) {
        return a-b-height-95
      }
      return a - b - 95
    } else if (height!==undefined) {
      let a = window.innerHeight
      return a - height - 95
    } else {
      console.log('未传id和高度')
    }
  },
  /**
   * @desc 根据元素id算出宽度
   */
  getWidth(id,width) {
    if (id!=='') {
      let element = '#'+id
      let a = window.innerWidth
      let b = document.querySelector(element)?.clientWidth
      return a - b - 60
    } else if (width!==undefined) {
      let a = window.innerWidth
      console.log(a,width)
      return a - width - 60
    } else {
      console.log('未传id和宽度')
    }
  },
  /**
   * @desc 测试方法
   */
  test(vue) {
    console.log(vue)
    vue.$message.success('测试import成功')
  }
}

